import { createRouter, createWebHistory, Router, RouteRecordRaw } from 'vue-router'
import { IdentificationModule } from "@/store/modules/identification/identification";
import { VueNavigationClient } from '@/services/MsalService';
import { auth } from '@/store/modules/identification/MsalAuth';
import { AzureIdentificationModule, grabToken } from '@/store/modules/identification/azureIdentification';
// import axios from 'axios';
import { QueryStringModule } from '../store/modules/QueryStringModule';

const routes: Array<RouteRecordRaw> = [
    {
        path: "/Forbidden",
        name: "Forbidden",
        component: () => import(/* webpackChunkName: "forbidden" */ "@/views/Forbidden.vue"),
        meta: { title: "Steel King Industries, Inc." },
    },
    {
        path: "/Reset",
        name: "Reset",
        component: { render: () => null }, // For redirect
        meta: { title: "Steel King Industries, Inc." }
    },
    {
        path: "/Login",
        name: "Login",
        component: () => import(/* webpackChunkName: "azureview" */ "@/views/SignInSignUp.vue"), // For embedded azure content
        meta: { title: "Steel King Industries, Inc." }
    },
    {
        path: "/LoginRedirect",
        name: "Login Redirect",
        component: { render: () => null }, // For redirect
        meta: { title: "Steel King Industries, Inc." }
    },
    {
        path: "/SSO",
        name: "Single Sign On",
        component: { render: () => null }, // For redirect
        meta: { title: "Steel King Industries, Inc." }
    },
    {
        path: "/Logout",
        name: "Logout",
        component: { render: () => null }, // For redirect
        meta: { title: "Steel King Industries, Inc." }
    },
    {
        path: "/ChangePassword",
        name: "Change Password",
        component: { render: () => null }, // For redirect
        meta: { title: "Steel King Industries, Inc." }
    },
    {
        path: "/customer",
        name: "Customer",
        component: () => import(/* webpackChunkName: "customer" */ "../views/Customer.vue"),
        meta: { title: "Dashboard", authorize: ["Portal-Customer", "SalesAdmin", "IT"] },
    },
    {
        path: '/SiteMap',
        name: 'SiteMap',
        component: () => import(/* webpackChunkName: "sitemap" */ '@/views/SiteMap.vue'),
        meta: { title: 'Site Map', authorize: ["IT"] }
    },
    {
        path: "/ActiveReports",
        name: "Active Reports",
        component: () =>
            import(/* webpackChunkName: "activereports" */ "@/views/admin/ActiveReportEditor.vue"),
        meta: { title: "Active Reports", authorize: ["IT"] },
    },
    {
        path: "/customer/leadtimes",
        name: "Lead Times",
        component: () =>
            import(/* webpackChunkName: "leadtimes" */ "@/views/LeadTimes.vue"),
        meta: { title: "Lead Times", authorize: ["Portal-Customer", "IT"] },
    },
    {
        path: "/customer/requestliterature",
        name: "Request Literature",
        component: () =>
            import(/* webpackChunkName: "requestliterature" */ "@/views/RequestLiterature.vue"),
        meta: { title: "Request Literature", authorize: ["Portal-Customer", "IT"] },
    },
    {
        path: "/customer/announcementarchive",
        name: "Announcement Archive",
        component: () => import(/* webpackChunkName: "announcementarchive" */ "@/views/AnnouncementArchive.vue"),
        meta: { title: "Announcement Archive", authorize: ["Portal-Customer", "IT"] },
    },
    {
        path: "/customer/photodownloads",
        name: "Photo Downloads",
        component: () => import(/* webpackChunkName: "photodownloads" */ "@/views/PhotoDownloads.vue"),
        meta: { title: "Photo Downloads", authorize: ["Portal-Customer", "IT"] },
    },    {
        path: "/customer/logodownloads",
        name: "Logo Downloads",
        component: () => import(/* webpackChunkName: "logodownloads" */ "@/views/LogoDownloads.vue"),
        meta: { title: "Logo Downloads", authorize: ["Portal-Customer", "IT"] },
    },
    {
        path: "/customer/announcement",
        name: "Announcement",
        component: () => import(/* webpackChunkName: "announcementarchive" */ "@/views/Announcement.vue"),
        meta: { title: "Announcement", authorize: ["Portal-Customer", "IT"] },
    },
    {
        path: "/customer/qslisting",
        name: "QSListing",
        component: () => import(/* webpackChunkName: "announcementarchive" */ "@/views/QSListing.vue"),
        meta: { title: "48 Hour Quick Ship", authorize: ["Portal-Customer", "IT"] },
    },
    {
        path: '/admin/users',
        name: 'Users',
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/Users.vue"),
        meta: { title: "Users", authorize: ["SalesAdmin", "IT"] }
    },
    {
        path: '/admin/pages',
        name: 'Pages',
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/Pages.vue"),
        meta: { title: "Pages", authorize: ["SalesAdmin", "IT"] }
    },
    {
        path: '/admin/announcements',
        name: 'AnnouncementsAdmin',
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/Announcements.vue"),
        meta: { title: "Announcements", authorize: ["SalesAdmin", "IT"] }
    },    {
        path: '/admin/photodownloads',
        name: 'PhotoDownloadsAdmin',
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/PhotoDownloadsAdmin.vue"),
        meta: { title: "Photo Downloads Admin", authorize: ["SalesAdmin", "IT"] }
    },{
        path: '/admin/logodownloads',
        name: 'LogoDownloadsAdmin',
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/LogoDownloadsAdmin.vue"),
        meta: { title: "Logo Downloads Admin", authorize: ["SalesAdmin", "IT"] }
    },
    {
        path: '/customer/quotetool',
        name: 'Quote Tool',
        component: () => import(/* webpackChunkName: "quotetool" */ "@/views/quoting/QuoteTool.vue"),
        meta: { title: "Quote Tool", authorize: ["Portal-Customer", "IT"] }
    },
    {
        path: '/customer/orderstatus',
        name: 'Order Status',
        component: () => import(/* webpackChunkName: "orderstatus" */ "@/views/OrderStatus.vue"),
        meta: { title: "Order Status", authorize: ["Portal-Customer", "IT"] }
    },
    //{
    //    path: '/customer/teamaccounts',
    //    name: 'Team Members',
    //    component: () => import(/* webpackChunkName: "account" */ "@/views/TeamMembers.vue"),
    //    meta: { title: "Team Members", authorize: ["DealerTeamLeader", "SalesAdmin", "IT"] }
    //},
    {
        path: '/Account/Information',
        name: 'Account Information',
        component: () => import(/* webpackChunkName: "account" */ "@/views/Account/UserInformation.vue"),
        meta: { title: "Account Information", authorize: [] }
    },
    {
        path: "/Identify",
        name: "Identify",
        component: () =>
            import(/* webpackChunkName: "identify" */ "@/views/Identify.vue"),
        meta: { title: "Identify" },
    },
    { path: '/:pathMatch(.*)*', name: 'not-found', component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue') },
    // if you omit the last `*`, the `/` character in params will be encoded when resolving or pushing
    { path: '/:pathMatch(.*)', name: 'bad-not-found', component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue') },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

// ---------------------------------------------------------------------------------------------------------------------
// authentication
// ---------------------------------------------------------------------------------------------------------------------

// hook MSAL into router
const client = new VueNavigationClient(router)

// This callback runs before every route change, including on page load.
router.beforeEach(async (to, from, next) => {
    const authorize = to?.meta?.authorize;
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)
    //If a route with a title was found, set the document (page) title to that value.
    if (nearestWithTitle) document.title = nearestWithTitle.meta.title as string

    // initialized
    if (process.env.VUE_APP_USE_MSAL === 'true' && !auth.initialized && to.path.toLowerCase() != '/loginredirect') {
        QueryStringModule.setPath(to.fullPath) // To help navigate back after logging in
        let account = await auth.initialize(client)
        //console.log(account)
        if (account === null) {
            account = await auth.loginSilent()
            //console.log(account)
            if (account === null) {
                //return next({ path: "/LoginRedirect" });
                if (to.path.toLowerCase() == '/sso') {
                    const result = await auth.loginSSO()
                }
                return next({ path: "/Login" });
            } // else continue on with navigation
        }
    }

    if (process.env.VUE_APP_USE_MSAL === 'true') {
        switch (to.path.toLowerCase()) {
            case '/reset':
                await auth.resetCache()
                window.location.href = window.location.origin
                return next()
            case '/loginredirect':
                if (!auth.initialized) {
                    console.log('not initialized')
                    const account = await auth.initialize(client)
                    console.log(account)
                    if (account != null) {
                        window.parent.postMessage('loginComplete', '*');
                        return next(false)
                    }
                } else {
                    console.log('initialized')
                }
                const resultLogin = await auth.loginRedirect()
                window.parent.postMessage('loginComplete', '*');
                return next(false)
            case '/sso':
                console.log(auth.account)
                if (auth.account) {
                    return next({ path: "/" })
                }
                return next(false)
            case '/logout':
                await auth.logoutRedirect()
                return next()
            case '/changepassword':
                const result = await auth.changePasswordRedirect()
                result ? next() : next(false)
                break;
            //case '/changepasswordredirect':
            //    try {
            //        const resultChangePassword = await auth.changePasswordRedirect()
            //        window.parent.postMessage('changePasswordComplete', '*');
            //    } catch {
            //        window.parent.postMessage('userCancelledSelfService', '*');
            //    } finally {
            //        return next(false)
            //    }
        }
    }

    switch (to.path.toLowerCase()) {
        case '/customer/quotetool':
            if (to.query.QuoteID) {
                QueryStringModule.setQuoteID(to.query.QuoteID.toString())
            }
            else {
                QueryStringModule.setQuoteID('0');
            }
            break;
    }

    if (authorize) {
        if (process.env.VUE_APP_USE_MSAL === 'false' && !IdentificationModule.token) {
            // not Identified so redirect to Identify page with the return url
            return next({ path: "/Identify", query: { returnUrl: to.fullPath } });
        }

        // authorized
        if (process.env.VUE_APP_USE_MSAL === 'true' && AzureIdentificationModule.authenticated) {
            if (!AzureIdentificationModule.isTokenValid) {
                await grabToken()
                const result = dispatchEvent(new Event('RefreshTokenTimer'))
            }
            //if (!AzureIdentificationModule.hasTokenData) {
            //    console.log('Uh oh')
            //}
            // check if route is restricted by role
            if (authorize.length && AzureIdentificationModule.hasTokenData && AzureIdentificationModule.isInRole('Portal-Customer') &&
                !authorize.some((role: string) => AzureIdentificationModule.isInRole(role))
            ) {
                // role not authorized so redirect to home page
                AzureIdentificationModule.setError("403")
                console.log('banished :(')
                return next({ path: "/Forbidden" });
            } else if (!AzureIdentificationModule.isInRole('Portal-Customer') && to.path.toLowerCase() != '/account/information') {
                console.log('New user detected.')
                return next({ path: "/Account/Information" });
            }
            return next();
        } else if (process.env.VUE_APP_USE_MSAL === 'true' && !AzureIdentificationModule.authenticated) {
            try {
                //return next({ path: "/LoginRedirect" });
                return next({ path: "/Login" });
            } catch (err) {
                console.log(err)
                AzureIdentificationModule.setError("Unknown")
                console.log('banished :"(')
                return next({ path: "/Forbidden" });
                //return next(false) // redirect to error page? 
            }
        }
    }

    next();
});

export default router;
